import { ReactNode, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { TickIsolated } from '@images/icons';

type CardProps = {
  id: string;
  isActive: boolean;
  onToggle: () => void;
  icon: ReactNode;
  title: string;
  content: ReactNode;
  hideBorder: boolean;
  topRadius: boolean;
  bottomRadius: boolean;
  completion?: number;
  accordionActivated?: boolean;
};

const Completion = ({ completion }: { completion: number }) => {
  if (completion === 100) {
    return (
      <span className="flex items-center gap-1 font-heading text-lg font-medium md:text-xl">
        <TickIsolated className="h-5 fill-primary" /> Completed
      </span>
    );
  }
  return <span className="font-lg font-light">{completion}%</span>;
};

export const Card = ({
  id,
  isActive,
  icon,
  onToggle,
  title,
  content,
  hideBorder,
  topRadius,
  bottomRadius,
  completion,
  accordionActivated,
}: CardProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      setMaxHeight(isActive ? contentHeight : 0);

      const focusableElements = contentRef.current.querySelectorAll<HTMLElement>(
        'a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])'
      );

      focusableElements.forEach(element => {
        if (!isActive) {
          element.setAttribute('tabindex', '-1');
        } else {
          if (element.getAttribute('tabindex') === '-1') {
            element.removeAttribute('tabindex');
          }
        }
      });
    }

    if (isActive) {
      const cardElement = cardRef.current;

      if (cardElement && accordionActivated && window.innerWidth < 768) {
        const yOffset = -50;

        setTimeout(() => {
          const rect = cardElement.getBoundingClientRect();
          const yPosition = rect.top + window.scrollY + yOffset;

          window.scrollTo({ top: yPosition, behavior: 'smooth' });
        }, 300);
      }
    }
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      id={id}
      ref={cardRef}
      className={twMerge(
        'z-10 w-[calc(100%-50px)] overflow-hidden border-b border-neutral-300 bg-secondary transition-all duration-300',
        isActive && 'z-20 w-full rounded-3xl bg-white shadow-homeLarge duration-500',
        (isActive || hideBorder) && 'border-none',
        topRadius && 'rounded-t-3xl',
        bottomRadius && 'rounded-b-3xl'
      )}
    >
      <button
        className="flex w-full items-center justify-between px-5 py-6"
        onClick={onToggle}
        aria-expanded={isActive}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <div className="flex items-center gap-2 md:gap-5">
          <span className="flex w-12 justify-center">{icon}</span>
          <h3 className="text-xl font-medium md:text-2xl">{title}</h3>
        </div>
        <Completion completion={completion || 0} />
      </button>
      <div
        id={`${id}-content`}
        role="region"
        aria-labelledby={`${id}-header`}
        className={twMerge(
          'transition-all duration-300 ease-in-out',
          isActive ? 'opacity-100' : 'opacity-0',
          'overflow-hidden'
        )}
        style={{ maxHeight: isActive ? `${maxHeight}px` : '0px' }}
      >
        <div ref={contentRef}>{content}</div>
      </div>
    </div>
  );
};
