import { useSelector } from 'react-redux';

import { ManualTask } from '@components/ManualTask';
import { Map } from '@components/Map';
import {
  BuyerProtectionSignpost,
  ConveyancingSignpost,
  MortgageSignpost,
} from '@components/Signpost';

import { selectProfile } from '@features/profile/profile';

import {
  Bulb,
  Conveyancing as ConveyancingIcon,
  Insurance,
  MapPin,
  Payment,
  Pound,
  Removals,
  TickIsolated,
} from '@images/icons';

import { convertAddressToSingleLine } from '@utils/convertAddress';

const ExampleContent = () => (
  <div className="flex w-full flex-col gap-4 px-7 pb-8 md:w-1/2">
    <h4 className="text-2xl font-medium">What to expect</h4>
    <p className="text-lg font-light">
      With a conveyancer or solicitor on your side, you can feel confident you won&apos;t get any
      nasty surprises down the line. Be prepared for the conveyancing process to take several weeks,
      depending on the complexity of the sale.
    </p>
    <a href="/" target="_blank" className="underline hover:no-underline">
      Read our guide to conveyancing
    </a>
  </div>
);

const ContentBlock = ({ title, content, link, buttonText }: any) => (
  <div className="flex w-full max-w-xl flex-col gap-4">
    <h4 className="text-2xl font-medium">{title}</h4>
    <p className="text-lg font-light">{content}</p>
    <a href={link} target="_blank" className="underline hover:no-underline" rel="noreferrer">
      {buttonText}
    </a>
  </div>
);

const FindAProperty = () => {
  const { value: profile } = useSelector(selectProfile);

  const postcode = profile?.properties[0].postcode || '';
  const address = profile?.properties?.[0] ? convertAddressToSingleLine(profile.properties[0]) : '';

  return (
    <>
      <div className="flex flex-col items-center gap-10 px-7 pb-12 md:grid md:grid-cols-2 md:gap-14 md:pb-8 md:pt-0">
        <div className="flex w-full max-w-xl flex-col gap-5">
          {postcode && (
            <div className="block h-40 w-full overflow-hidden rounded-xl">
              <Map postcode={postcode} />
            </div>
          )}
          <span className="font-heading text-2xl font-medium">£688,780</span>
          <h4 className="text-xl font-medium">{address}</h4>
        </div>
        <hr className="w-full border-stone-200 md:hidden" />
        <BuyerProtectionSignpost />
      </div>
      <div className="bg-why flex flex-col gap-4 p-7 pb-8">
        <h4 className="text-2xl font-medium">My check list</h4>
        <ManualTask uiName="Sale agreed" />
      </div>
    </>
  );
};

const Conveyancing = () => (
  <>
    <div className="flex flex-col items-center gap-10 px-7 pb-12 md:grid md:grid-cols-2 md:gap-14 md:pb-8 md:pt-0">
      <ConveyancingSignpost />
      <hr className="w-full border-stone-200 md:hidden" />
      <ContentBlock
        title="What to expect"
        content="With a conveyancer or solicitor on your side, you can feel confident you won't get any nasty surprises down the line. Be prepared for the conveyancing process to take several weeks, depending on the complexity of the sale."
        link="/"
        buttonText="Read our guide to conveyancing"
      />
    </div>
    <div className="bg-why flex flex-col gap-4 p-7 pb-8">
      <h4 className="text-2xl font-medium">My check list</h4>
      <ManualTask uiName="Instruct a conveyancer" />
    </div>
  </>
);

const Finances = () => (
  <>
    <div className="flex flex-col items-center gap-10 px-7 pb-12 md:grid md:grid-cols-2 md:gap-14 md:pb-8 md:pt-0">
      <MortgageSignpost />
      <hr className="w-full border-stone-200 md:hidden" />
      <div className="flex flex-col items-center gap-8">
        <ContentBlock
          title="Mortgage rate checker"
          content="Work out what size of mortgage you could afford and how much mortgage repayments are for different loans"
          link="/"
          buttonText="Use the calculator"
        />
        <ContentBlock
          title="What to expect"
          content="When you're buying a home, the mortgage process can seem complex and overwhelming, but it doesn't have to be with a mortgage broker on hand to guide you through the process."
          link="/"
          buttonText="Read our guide to the mortgage process"
        />
      </div>
    </div>
    <div className="bg-why flex flex-col gap-4 p-7 pb-8">
      <h4 className="text-2xl font-medium">My check list</h4>
      <ManualTask uiName="Instruct a broker" />
      <ManualTask uiName="Apply for a mortgage" />
      <ManualTask uiName="Sign mortgage offer" />
    </div>
  </>
);

export const SECTIONS = [
  {
    id: 'find-property',
    icon: <MapPin className="h-10 w-10 fill-primary" />,
    title: 'Find a property',
    content: <FindAProperty />,
    completion: 100,
  },
  {
    id: 'conveyancing',
    icon: <ConveyancingIcon className="h-10 w-10 fill-primary" />,
    title: 'Conveyancing',
    content: <Conveyancing />,
    completion: 0,
  },
  {
    id: 'finances',
    icon: <Pound className="h-10 w-10 fill-primary" />,
    title: 'Finances',
    content: <Finances />,
  },
  {
    id: 'insurance',
    icon: <Insurance className="h-10 w-10 fill-primary" />,
    title: 'Insurance',
    content: <ExampleContent />,
  },
  {
    id: 'exchange',
    icon: <Payment className="h-10 w-10 fill-primary" />,
    title: 'Exchange',
    content: <ExampleContent />,
  },
  {
    id: 'removals',
    icon: <Removals className="h-10 w-10 fill-primary" />,
    title: 'Removals',
    content: <ExampleContent />,
  },
  {
    id: 'move ready',
    icon: <Bulb className="h-10 w-10 fill-primary" />,
    title: 'Get move ready',
    content: (
      <div className="flex flex-col gap-5">
        <ExampleContent />
        <ExampleContent />
        <ExampleContent />
        <ExampleContent />
        <ExampleContent />
        <ExampleContent />
        <ExampleContent />
        <ExampleContent />
        <ExampleContent />
      </div>
    ),
  },
  {
    id: 'complete',
    icon: <TickIsolated className="h-10 w-8 fill-primary" />,
    title: 'Complete',
    content: <ExampleContent />,
  },
];
