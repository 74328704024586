import { toast, ToastOptions } from 'react-toastify';

import { Alert, Tick } from '@images/icons';

import { useMediaQuery } from './useMediaQuery';

export const useToast = () => {
  const { IS_MOBILE } = useMediaQuery();

  const DEFAULT_SETTINGS: Partial<ToastOptions> = {
    position: IS_MOBILE ? 'top-center' : 'bottom-right',
    hideProgressBar: true,
    closeOnClick: true,
  };

  const dispatchSuccessToast = (message: string) => {
    toast.success(message, {
      ...DEFAULT_SETTINGS,
      theme: 'light',
      icon: <Tick className="fill-green-600" />,
    });
  };

  const dispatchErrorToast = (message: string) => {
    toast.error(message || 'Something went wrong', {
      ...DEFAULT_SETTINGS,
      theme: 'colored',
      icon: <Alert className="fill-white" />,
    });
  };

  return { dispatchSuccessToast, dispatchErrorToast };
};
