import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';

import { useToast } from '@hooks/useToast';

import { CheckboxEmpty, CheckboxTick, Dash } from '@images/icons';

import { InProgress } from './InProgressAnimation';

export const getIcon = (status: string, manualTask?: boolean) => {
  switch (status) {
    case 'COMPLETED':
      return (
        <CheckboxTick
          className="ml-[5px] mt-[-7px] w-[25px] fill-primary"
          aria-label="Task Completed"
        />
      );
    case 'IN_PROGRESS':
      return <InProgress />;
    case 'NOT_STARTED':
    default:
      return manualTask ? (
        <CheckboxEmpty className="w-5 fill-primary" aria-label="Task Not Started" />
      ) : (
        <Dash className="w-5 fill-primary" aria-label="Task Not Started" />
      );
  }
};

export const ManualTask = ({ uiName, status }: any) => {
  const [taskStatus, setTaskStatus] = useState('');
  const { dispatchErrorToast, dispatchSuccessToast } = useToast();

  useEffect(() => {
    setTaskStatus(status);
  }, [status]);

  const handleUpdateTask = async () => {
    const initialStatus = taskStatus;

    try {
      setTaskStatus('COMPLETED');
      dispatchSuccessToast(`Task "${uiName}" updated successfully`);
    } catch (error) {
      Sentry.captureException(error);
      setTaskStatus(initialStatus);
      dispatchErrorToast(`Something went wrong updating the task "${uiName}"`);
    }
  };

  return (
    <button
      className="flex cursor-pointer select-none items-center gap-2 disabled:cursor-default"
      onClick={() => handleUpdateTask()}
      aria-label={`Mark ${uiName} as ${taskStatus === 'COMPLETED' ? 'not completed' : 'complete'}`}
      aria-pressed={taskStatus === 'COMPLETED'}
    >
      <span className="flex w-10 items-center justify-center">{getIcon(taskStatus, true)}</span>
      <span className="text-primary">{uiName}</span>
    </button>
  );
};
