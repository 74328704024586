import { LinkButton } from '@components/Button';

import { HouseCross } from '@images/icons';

type SignpostProps = {
  subtitle: string;
  title: string;
  price?: number;
  text: string;
  buttonText: string;
  buttonLink: string;
  newTab?: boolean;
};

export const Signpost = ({
  subtitle,
  title,
  price,
  buttonText,
  buttonLink,
  text,
  newTab,
}: SignpostProps) => {
  return (
    <div className="md:max-w-auto mx-auto flex w-full max-w-xl flex-col gap-5 rounded-3xl">
      <div className="flex w-full items-start justify-between gap-3">
        <div className="flex flex-col gap-2">
          <span className="font-bold">{subtitle}</span>
          <h4 className="font-body text-xl font-medium">
            {title}
            {!!price && (
              <>
                {' '}
                £{price}
                <span className="text-lg">.00</span>
              </>
            )}
          </h4>
        </div>
        <span className="flex h-12 w-12 min-w-12 items-center justify-center rounded-full bg-home">
          <HouseCross className="fill-white" height="28px" />
        </span>
      </div>
      <div className="flex w-full flex-col items-start gap-6">
        <p className="text-left text-base">{text}</p>

        <LinkButton href={buttonLink} target={newTab ? '_blank' : ''}>
          {buttonText}
        </LinkButton>
      </div>
    </div>
  );
};

export const BuyerProtectionSignpost = () => (
  <Signpost
    subtitle="Protect your move"
    title="Home Buyers Protection from"
    price={74}
    text="Did you know you can cover your legal, survey and mortgage costs should your purchase fall through."
    buttonText="Protect your move fees"
    buttonLink="https://home.rhinohomeprotect.com/quote/"
    newTab
  />
);

export const SellerProtectionSignpost = () => (
  <Signpost
    subtitle="Protect your move"
    title="Home Sellers Protection from"
    price={69}
    text="Did you know you can cover your legal costs should your sale fall through."
    buttonText="Protect your move fees"
    buttonLink="https://home.rhinohomeprotect.com/quote/"
    newTab
  />
);

export const EnergySignpost = () => (
  <Signpost
    subtitle="Get move ready"
    title="Save on your energy bills"
    text="Home can help find the perfect tariff for your place in less than 10 minutes, and even makes switching a breeze."
    buttonText="View energy tariffs"
    buttonLink="/wizard/energy/"
    newTab
  />
);

export const BroadbandSignpost = () => (
  <Signpost
    subtitle="Get move ready"
    title="Set up broadband"
    text="Find the right broadband deal for you and get set up in less than 5 minutes."
    buttonText="View broadband deals"
    buttonLink="/wizard/broadband/"
    newTab
  />
);

export const ConveyancingSignpost = ({ cost = 950 }: { cost?: number }) => (
  <Signpost
    subtitle="To do"
    title={`Instruct a conveyancer from £${cost}.00 and track your progress`}
    text={`Coneyancing for your sale from just £${cost}.00. View my quotes`}
    buttonText="View conveyancing quotes"
    buttonLink="#"
    newTab
  />
);

export const MortgageSignpost = () => (
  <Signpost
    subtitle="To do"
    title="Find your best mortgage deal, fast and hassle free"
    text="Access to thousands of mortgages from 70+ lenders without being charged a penny"
    buttonText="Find the best mortgage"
    buttonLink="#"
    newTab
  />
);
