import React, { useState, useEffect } from 'react';

import { Card } from './Card';
import { SECTIONS } from './Sections';

export const MoveWizard: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [hasActivatedAccordion, setHasActivatedAccordion] = useState<boolean>(false);

  const toggleSection = (index: number) => {
    setActiveIndex(activeIndex === index ? 0 : index);
    setHasActivatedAccordion(true);
  };

  useEffect(() => {
    const index = SECTIONS.findIndex(section => !section.completion);
    setActiveIndex(index === -1 ? SECTIONS.length - 1 : index);
  }, []);

  return (
    <div className="flex w-full flex-col items-center gap-0 ">
      {SECTIONS.map((section, index) => (
        <Card
          id={section.id}
          key={index}
          hideBorder={activeIndex - 1 === index || index === SECTIONS.length - 1}
          isActive={activeIndex === index}
          onToggle={() => toggleSection(index)}
          title={section.title}
          content={section.content}
          icon={section.icon}
          topRadius={index === 0}
          bottomRadius={index === SECTIONS.length - 1}
          completion={section.completion}
          accordionActivated={hasActivatedAccordion}
        />
      ))}
    </div>
  );
};
